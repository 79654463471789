import { createReducer } from "redux-act";
import * as action from "../actions/identityAction";

const initialState = {
  config: {
    response_type: "id_token token",
    scope: "openid profile api"
  }
};

const identityReducer = createReducer(
  {
    [action.changeConfig]: (state, payload) => {
      return {
        ...state,
        config: payload
      };
    }
  },
  initialState
);

export default identityReducer;
