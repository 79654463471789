import React, { useEffect, useState } from "react";
import Axios from "axios";

import Loading from "./Loading";

import Keycloak from "keycloak-js";
import { ReactKeycloakProvider } from "@react-keycloak/web";

function Auth(props) {
  const [loading, setLoading] = useState(true);
  const [keycloak, setKeycloak] = useState(null);
  const [keycloakReady, setKeycloakReady] = useState(false);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = () => {
    setLoading(true);

    Axios.get(`api/keycloak/config`).then((response) => {
      setKeycloak(new Keycloak({ ...response.data }));
      setLoading(false);
    });
  };

  if (loading || keycloak == null) return <Loading />;

  const onTokenExpired = () => {
    keycloak.updateToken(300);
  };

  return (
    <ReactKeycloakProvider
      initOptions={{
        onLoad: "login-required",
      }}
      authClient={keycloak}
      onEvent={(event) => {
        if (event === "onReady") setKeycloakReady(true);
        if (event === "onTokenExpired") onTokenExpired();
      }}
      onTokens={(event) => {
        localStorage.setItem("token", event.token);
      }}
    >
      {keycloakReady ? props.children : ""}
    </ReactKeycloakProvider>
  );
}

export default Auth;
