import { createReducer } from "redux-act";

import { exists } from "../../utils/LanguageEnum";
import * as action from "../actions/languageAction";

const initialState = {
  language: "sk"
}

const setInitialState = () => {
  let localStorageLanguage = localStorage.getItem("language");

  if(exists(localStorageLanguage))
    initialState.language = localStorageLanguage;

  return initialState;
}

const languageReducer = createReducer(
  {
    [action.changeLanguage]: (state, payload) => {
      return {
        ...state,
        language: payload
      };
    }
  },
  setInitialState()
);

export default languageReducer;
