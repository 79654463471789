import { createReducer } from "redux-act";
import jwtDecode from "jwt-decode";

import * as action from "../actions/authenticationAction";
import { RoleEnum } from "../../utils/roles";

const initialState = {
    isAuthenticated: true,
    token: "",
    email: "",
    roles: [RoleEnum.Administrator],
    name: "",
}

const setInitialState = () => {
    return initialState;
    
    var token = localStorage.getItem("token");

    if(token === null)
        return initialState;

    var decodedToken = jwtDecode(token);
    
    if(!Array.isArray(decodedToken.role))
        decodedToken.role = [decodedToken.role];

    return {
        isAuthenticated: true,
        token: token,
        email: decodedToken.email,
        roles: [RoleEnum.Administrator],
        name: decodedToken.unique_name
    };
}

const authenticationReducer = createReducer(
    {
        [action.setUser]: (state, payload) => {
            localStorage.setItem("token", payload.access_token);
    
            return {
                ...state,
                user: payload
            }
        },
        [action.clearUser]: (state) => {
            localStorage.removeItem("token");

            return {
                ...state,
                user: {}
            }
        },
        [action.setToken]: (state, payload) => {
            var decodedToken = jwtDecode(payload.token);
    
            if(!Array.isArray(decodedToken.role))
                decodedToken.role = [decodedToken.role];
            
            localStorage.setItem("token", payload.token);

            return {
                ...state,
                isAuthenticated: true,
                token: payload.token,
                email: decodedToken.email,
                roles: decodedToken.role,
                name: decodedToken.unique_name
            }
        },
        [action.logout]: (state) => {
            localStorage.removeItem("token");

            return initialState
        }
    },
    setInitialState()
);

export default authenticationReducer;