import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";

import Loading from "./admin/Loading";

const ProtectedRoute = (children) => {
  const { keycloak, initialized } = useKeycloak();
  const { component: Component, ...rest } = children;
  const requireRole =
    children.requiredRoles != undefined && children.requiredRoles.length > 0;

  let intersection =
    !requireRole ||
    children.requiredRoles.filter((rr) => keycloak.hasRealmRole(rr));

  if (!initialized) return <Loading />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (keycloak.authenticated && intersection.length > 0) {
          return <Component {...props} />;
        } else {
          return <Redirect to={children.redirectTo} />;
        }
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapDispatchToProps)(ProtectedRoute);
