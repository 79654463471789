import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

import Routers from "./components/Routers";

import global from "./translations/global.json";
import adminGlobal from "./translations/admin/global.json";
import presentationGlobal from "./translations/presentation/global.json";
import Loading from "./components/admin/Loading";
import Auth from "./components/admin/Auth";
import { getLocaleByShortcut } from "./utils/antdLocale.jsx";
import { ConfigProvider } from "antd";

const LayoutPresentation = React.lazy(() =>
  import("./components/presentation/LayoutPresentation")
);
const LayoutAdmin = React.lazy(() => import("./components/admin/LayoutAdmin"));

const Themes = {
  light: `${process.env.PUBLIC_URL}/css/antd.css`,
  dark: `${process.env.PUBLIC_URL}/css/antd.dark.css`,
};

function App(props) {
  const language = useSelector((state) => state.language.language);
  const theme = useSelector((state) => state.userSettings.theme);
  const history = useHistory();
  const location = useLocation();
  const combinedTranslation = {
    en: {
      ...global["en"],
      ...adminGlobal["en"],
      ...presentationGlobal["en"],
    },
    sk: {
      ...global["sk"],
      ...adminGlobal["sk"],
      ...presentationGlobal["sk"],
    },
  };

  useEffect(() => {
    if (location.pathname.includes("www"))
      history.push(location.pathname.replace("www.", ""));
  }, []);

  const getLayout = () => {
    if (
      history.location.pathname.includes("/admin") ||
      history.location.pathname === "/"
    )
      return (
        <Auth>
          <LayoutAdmin>
            <Suspense fallback={<Loading />}>
              <Routers />
            </Suspense>
          </LayoutAdmin>
        </Auth>
      );
    else
      return (
        <LayoutPresentation>
          <Suspense fallback={<Loading />}>
            <Routers />
          </Suspense>
        </LayoutPresentation>
      );
  };

  return (
    <IntlProvider
      key={language}
      locale={language}
      messages={combinedTranslation[language]}
    >
      <ThemeSwitcherProvider themeMap={Themes} defaultTheme={theme}>
        <Suspense fallback={<Loading />}>
          <ConfigProvider locale={getLocaleByShortcut(language)}>
            {getLayout()}
          </ConfigProvider>
        </Suspense>
      </ThemeSwitcherProvider>
    </IntlProvider>
  );
}

export default App;
