import { createReducer } from "redux-act";

import * as action from "../actions/userSettingsAction";

const initialState = {
  theme: "light",
  detail: {},
  account: null,
};

const getAccount = () => {
  let localStorageAccount = localStorage.getItem("account");

  if (localStorageAccount === null) return null;

  try {
    let account = JSON.parse(localStorageAccount);

    if (
      account["id"] === undefined ||
      account["name"] === undefined ||
      account["cid"] === undefined
    ) {
      localStorage.removeItem("account");

      return null;
    }

    return account;
  } catch (exception) {
    localStorage.removeItem("account");

    return null;
  }
};

const setInitialState = () => {
  let localStorageTheme = localStorage.getItem("theme");
  let account = getAccount();

  if (localStorageTheme === "light" || localStorageTheme === "dark")
    initialState.theme = localStorageTheme;

  if (account !== null) initialState.account = account;

  return initialState;
};

const userSettingsReducer = createReducer(
  {
    [action.changeTheme]: (state, payload) => {
      return {
        ...state,
        theme: payload,
      };
    },
    [action.setDetails]: (state, payload) => {
      return {
        ...state,
        detail: payload,
      };
    },
    [action.setOtherAccount]: (state, payload) => {
      localStorage.setItem("account", JSON.stringify(payload));

      return {
        ...state,
        account: payload,
      };
    },
    [action.clearDetails]: (state, payload) => {
      localStorage.removeItem("account");
      
      return {
        ...state,
        account: null,
        detail: {},
      };
    },
  },
  setInitialState()
);

export default userSettingsReducer;
