import { RoleEnum } from "./roles";

export const RouterEnum = Object.freeze({
  Registration: {
    Path: "/registration",
  },
  RegistrationSuccess: {
    Path: "/registration/success",
  },
  ActivateAccount: {
    Path: "/activate-account",
  },
  ActivateAccountSuccess: {
    Path: "/activate-account/success",
  },
  ActivateAccountFail: {
    Path: "/activate-account/fail",
  },
  ForgotPassword: {
    Path: "/forgot-password",
  },
  ForgotPasswordSuccess: {
    Path: "/forgot-password/success",
  },
  ForgotPasswordReset: {
    Path: "/forgot-password/reset",
  },
  ForgotPasswordResetSuccess: {
    Path: "/forgot-password/reset/success",
  },
  ForgotPasswordResetFail: {
    Path: "/forgot-password/reset/fail",
  },
  Dashboard: {
    Path: "/admin",
    Roles: [RoleEnum.Administrator],
  },
  IncomingPayments: {
    Path: "/admin/incoming-payments",
    Roles: [RoleEnum.Administrator],
  },
  IncomingPaymentDetail: {
    Path: "/admin/incoming-payments/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/admin/incoming-payments/${id}`,
  },
  OutgoingPayments: {
    Path: "/admin/outgoing-payments",
    Roles: [RoleEnum.Administrator],
  },
  OutgoingPaymentDetail: {
    Path: "/admin/outgoing-payments/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/admin/outgoing-payments/${id}`,
  },
  RefundPayments: {
    Path: "/admin/refund-payments",
    Roles: [RoleEnum.Administrator],
  },
  RefundPaymentsDetail: {
    Path: "/admin/refund-payments/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `admin/refund-payments/${id}`,
  },
  VirtualPayments: {
    Path: "/admin/virtual-payments",
    Roles: [RoleEnum.Administrator],
  },
  Invoices: {
    Path: "/admin/invoices",
    Roles: [RoleEnum.Administrator],
  },
  Integration: {
    Path: "/admin/integration",
    Roles: [RoleEnum.Administrator],
  },
  Support: {
    Path: "/admin/support",
    Roles: [RoleEnum.Administrator],
  },
  LoginCallback: {
    Path: "/auth/login-callback",
  },
  PaymentLinkOldGate: {
    Path: "/admin/payment-link-old-gate",
    Roles: [RoleEnum.Administrator],
  },
  PaymentLinkNewGate: {
    Path: "/admin/payment-link-new-gate",
    Roles: [RoleEnum.Administrator],
  },
  Settings: {
    Path: "/admin/settings",
    Roles: [RoleEnum.Administrator],
  },
  Client: {
    Path: "/admin/client",
    Roles: [RoleEnum.Administrator],
  },
  PaymentIntents: {
    Path: "/admin/payment-intents",
    Roles: [RoleEnum.Administrator],
  },
  PaymentIntentDetail: {
    Path: "/admin/payment-intents/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/admin/payment-intents/${id}`,
  },
  RecurringPaymentIntents: {
    Path: "/admin/recurring-payment-intents",
    Roles: [RoleEnum.Administrator],
  },
  RecurringPaymentIntentDetail: {
    Path: "/admin/recurring-payment-intents/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/admin/recurring-payment-intents/${id}`,
  },
  OnDemandPaymentIntents: {
    Path: "/admin/on-demand-payment-intents",
    Roles: [RoleEnum.Administrator],
  },
  OnDemandPaymentIntentDetail: {
    Path: "/admin/on-demand-payment-intents/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/admin/on-demand-payment-intents/${id}`,
  },
});
