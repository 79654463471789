import authentication from "./authenticationReducer";
import language from "./languageReducer";
import userSettings from "./userSettingsReducer";
import identity from "./identityReducer";

export default {
    authentication,
    language,
    userSettings,
    identity
};